<template>
    <div>
        <app-bar :title="badge.name"/>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <div class="pt-8 pb-12 px-8">
                <div class="pb-10">
                    <v-btn
                        @click="edit"
                        class="sm mr-5"
                        color="primary"
                    >{{ t('general_action_edit') }}
                    </v-btn>
                    <v-btn
                        class="sm mr-5"
                        color="primary"
                        outlined
                        @click.prevent="suspend(badge)"
                    >
                        {{ badge.published ? t('general_action_suspend') : t('general_action_unsuspend') }}
                    </v-btn>
                    <confirm-modal
                        :title="t('general_title_confirm_action')"
                        @agree="del(badge)"
                    >
                        <template #default="{ on, attrs }">
                            <v-btn
                                class="sm"
                                color="orange"
                                outlined
                                v-on="on"
                                v-bind="attrs"
                            >{{ t('general_action_delete') }}</v-btn>
                        </template>
                    </confirm-modal>
                </div>
                <div class="float-left mr-10" style="width: 394px; max-width: 394px">
                    <img
                        v-if="badge.photoUrl"
                        :src="badge.photoUrl"
                        width="394"
                    />
                    <div class="form-subtitle">{{ t('badge_label_description') }}:</div>
                    <div class="form-subtitle-description" v-html="badge.description"></div>
                    <div class="form-subtitle">{{ t('badge_label_motto') }}:</div>
                    <div class="form-subtitle-description" v-html="badge.motto"></div>
                </div>
                <div class="float-left" style="max-width: calc(100% - 434px)">
                    <card-unit :items="items()">
                        <template #title>{{ t('badge_label_id') }}: {{ badge.id }}</template>
                    </card-unit>
                </div>
            </div>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useEnums } from '@/core/defaults'
    import { Badge } from '@/core/models/badge'
    import moment from 'moment/moment'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import CardUnit from '@/components/CardUnit.vue'

    export default defineComponent({
        components: {
            ConfirmModal,
            CardUnit
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const i18n = useI18n()
            const enums = useEnums()
            const id = router.currentRoute.params['id']

            const state = reactive({
                overlay: true,
                currentTab: <number | null>null,
                photo: <File | undefined>undefined,
            })

            const capture = () => {
                store.dispatch('badge/get', { id: id, payload: { published: '', relations: 'section,events' } }).then(() => {
                    state.overlay = false
                })
            }

            const badgeModel = computed(() => <Badge>store.getters['badge/get'])

            const suspend = (badgeModel: Badge) => {
                state.overlay = true
                store.dispatch('badge/update', {
                    id: badgeModel.id,
                    payload: { published: ! badgeModel.published }
                }).then(() => {
                    store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    capture()
                })
            }

            const edit = () => router.push({ name: 'badges.show', params: { id: <any>badgeModel.value.id } })

            const del = (badgeModel: Badge) => {
                state.overlay = true
                store.dispatch('badge/delete', { id: badgeModel.id }).then(() => {
                    router.push({ name: 'badges' })
                })
            }

            const items = () => {
                let items = <any>[
                    { text: i18n.t('badge_label_section'), value: badgeModel.value.section.name },
                    { text: i18n.t('general_label_creation_date'), value: badgeModel.value.createdAt.format('DD/MM/YYYY') },
                    { text: i18n.t('badge_label_trigger'), value: enums.badgeTriggerTitle(badgeModel.value.trigger) },
                ]
                if (badgeModel.value.trigger === 'period_execution') {
                    items = items.concat([{
                        text: i18n.t('general_label_date_period'),
                        value: (badgeModel.value.startAt || '-') + ' / ' + (badgeModel.value.endAt || '-') + ' / ' + badgeModel.value.counter
                    }])
                }
                items = items.concat([
                    { text: i18n.t('badge_label_stat'), value: enums.badgeStatTitle(badgeModel.value.stat) },
                    { text: i18n.t('badge_label_sort_order'), value: badgeModel.value.sortOrder },
                    { text: i18n.t('badge_label_published'), value: badgeModel.value.published ? i18n.t('general_text_yes') : i18n.t('general_text_no') },
                    { text: i18n.t('badge_label_events'), value: badgeModel.value.events.length ? badgeModel.value.events.map(v => {
                        return { value: v.name, route: { name: 'events.view', params: { id: v.id } } }
                    }) : '-' },
                ])
                return items
            }

            onMounted(() => {
                capture()
            })

            return {
                capture,
                items,
                badge: badgeModel.value,
                moment,
                edit,
                del,
                suspend,
                ...useI18n(),
                ...useEnums(),
                ...toRefs(state)
            }
        }
    })
</script>
