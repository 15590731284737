<template>
    <v-flex fill-height>

        <v-navigation-drawer
            class="app-navigation-drawer"
            fixed
            permanent
            :v-scroll="false"
            width="220"
        >
            <template v-slot:prepend>
                <v-list>
                    <v-list-item class="pt-3">
                        <a
                            href="/"
                            @click.prevent="go({ name: 'home' })"
                        >
                            <img
                                src="/images/logo.svg"
                                width="158"
                                height="37"
                            />
                        </a>
                    </v-list-item>
                </v-list>
            </template>

            <v-list>
                <v-list-item-group
                    active-class="app-navigation-drawer-touched"
                    class="fill-height overflow-x-auto"
                >
                    <template v-for="item in items()">
                        <v-list-group
                            v-if="item.pages"
                            class="mb-5 subpages"
                            v-model="touched[item.to]"
                        >
                            <template v-slot:activator>
                                <v-list-item
                                    :key="`menu_${item.to}`"
                                    :to="item.to"
                                    :ripple="false"
                                    @click="touched[item.to] = true"
                                >
                                    <v-list-item-icon>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                            </template>
                            <v-list-item
                                v-for="page in item.pages"
                                :key="`page_${page.to}`"
                                :to="page.to"
                            >
                                <v-list-item-title>{{ page.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item
                            v-else
                            :key="`menu_${item.to}`"
                            :to="item.to"
                            :exact="item.exact"
                            class="mb-5"
                            @click="touched = {}"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </template>

                    <filer v-if="permitted('admin_file_manager')">
                        <template #default="{ on, attrs }">
                            <v-list-item
                                href="javascript:"
                                :key="`menu_file_manager`"
                                class="mb-5"
                                v-on="on"
                                v-bind="attrs"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-google-drive</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{ t('file_manager_title') }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </filer>
                    <v-list-item
                        v-if="permitted('admin_params')"
                        :key="`menu_params`"
                        to="/params"
                        class="mb-5"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-cogs</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ t('params_title') }}</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>

            <template v-slot:append>
                <v-list>
                    <v-list-item
                        to="/logout"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ t('logout_button') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </template>
        </v-navigation-drawer>

        <v-flex
            style="margin-left: 220px"
            fill-height
        >
            <router-view />
        </v-flex>

        <v-snackbar-queue
            :items="notifications"
            @remove="readNotification"
            top
            right
            close-button-icon="mdi-close"
        ></v-snackbar-queue>
    </v-flex>
</template>

<script lang="ts">
    import { defineComponent, computed, reactive, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useAuth } from '@/plugins/auth'

    interface ISubNavItem {
        title: string
        to: string
        permission?: string
    }

    interface INavItem {
        title: string
        icon: string
        to: string
        exact?: boolean
        permission?: string
        pages?: ISubNavItem[]
    }

    export default defineComponent({
        /**
         * @param props
         */
        setup(props) {
            const i18n = useI18n()
            const user = useAuth().user()

            const state = reactive({
                touched: <any>{},
            })

            const items = (): INavItem[] => {
                let items = <any>[]

                if (user.permitted('admin_dashboard')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('dashboard_title'),
                            icon: 'mdi-view-dashboard',
                            to: '/',
                            exact: true,
                        }
                    ])
                }

                if (user.permitted('admin_trainee')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('trainees_title'),
                            icon: 'mdi-account-multiple',
                            to: '/trainees',
                        }
                    ])
                }

                if (user.permitted('admin_workouts')) {
                    let subpages = <any>[]
                    if (user.permitted('admin_workout_executions')) {
                        subpages = subpages.concat(<any>[
                            {
                                title: i18n.t('workout_executions_title'),
                                to: '/workouts/workout-executions',
                            }
                        ])
                    }
                    if (user.permitted('admin_workout_feedbacks')) {
                        subpages = subpages.concat(<any>[
                            {
                                title: i18n.t('feedbacks_title'),
                                to: '/workouts/feedback',
                            }
                        ])
                    }
                    items = items.concat(<any>[
                        {
                            title: i18n.t('workouts_title'),
                            icon: 'mdi-swim',
                            to: '/workouts',
                            pages: subpages
                        }
                    ])
                } else if (user.permitted('admin_workout_executions') && user.permitted('admin_workout_feedbacks')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('workout_executions_title'),
                            icon: 'mdi-swim',
                            to: '/workouts/workout-executions',
                            pages: [
                                {
                                    title: i18n.t('feedbacks_title'),
                                    to: '/workouts/feedback',
                                }
                            ]
                        },
                    ])
                } else if (user.permitted('admin_workout_executions')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('workout_executions_title'),
                            icon: 'mdi-swim',
                            to: '/workouts/workout-executions',
                        },
                    ])
                } else if (user.permitted('admin_workout_feedbacks')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('feedbacks_title'),
                            icon: 'mdi-swim',
                            to: '/workouts/feedback',
                        },
                    ])
                }



                if (user.permitted('admin_programs') && user.permitted('admin_program_executions')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('programs_title'),
                            icon: 'mdi-calendar',
                            to: '/programs',
                            pages: [
                                {
                                    title: i18n.t('program_executions_title'),
                                    to: '/programs/program-executions',
                                },
                            ]
                        },
                    ])
                } else if (user.permitted('admin_programs')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('programs_title'),
                            icon: 'mdi-calendar',
                            to: '/programs',
                        },
                    ])
                } else if (user.permitted('admin_program_executions')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('program_executions_title'),
                            icon: 'mdi-calendar',
                            to: '/programs/program-executions',
                        },
                    ])
                }

                if (user.permitted('admin_races')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('races_title'),
                            icon: 'mdi-pool',
                            to: '/races',
                        },
                    ])
                }

                if (user.permitted('admin_badges')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('badges_title'),
                            icon: 'mdi-chevron-triple-up',
                            to: '/badges',
                        },
                    ])
                }

                if (user.permitted('admin_events')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('events_title'),
                            icon: 'mdi-map-marker-star',
                            to: '/events',
                        },
                    ])
                }

                if (user.permitted('admin_groups')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('groups_title'),
                            icon: 'mdi-account-group',
                            to: '/groups',
                        },
                    ])
                }

                if (user.permitted('admin_notifications')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('notifications_title'),
                            icon: 'mdi-bell-outline',
                            to: '/notifications',
                        },
                    ])
                }

                if (user.permitted('admin_glossary')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('glossary_title'),
                            icon: 'mdi-frequently-asked-questions',
                            to: '/glossary',
                        },
                    ])
                }

                if (user.permitted('admin_health')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('health_title'),
                            icon: 'mdi-medication',
                            to: '/health',
                        },
                    ])
                }

                if (user.permitted('admin_translations')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('translations_title'),
                            icon: 'mdi-google-translate',
                            to: '/translations',
                        },
                    ])
                }

                if (user.permitted('admin_statistic')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('statistic_title'),
                            icon: 'mdi-chart-bar',
                            to: '/statistic',
                            pages: [
                                {
                                    title: i18n.t('user_statistic_title'),
                                    to: '/statistic/user-statistic',
                                }
                            ]
                        },
                    ])
                }

                if (user.permitted('admin_email')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('email_title'),
                            icon: 'mdi-email',
                            to: '/email',
                        },
                    ])
                }

                if (user.permitted('admin_complaints')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('complaints_title'),
                            icon: 'mdi-scale-balance',
                            to: '/complaints',
                        }
                    ])
                }

                if (user.permitted('admin_users')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('users_title'),
                            icon: 'mdi-account',
                            to: '/users',
                        },
                    ])
                }

                if (user.permitted('admin_pages')) {
                    items = items.concat(<any>[
                        {
                            title: i18n.t('pages_title'),
                            icon: 'mdi-file-multiple-outline',
                            to: '/pages',
                        },
                    ])
                }

                return items
            }


            const store = useStore()
            const router = useRouter()

            const notifications = computed(() => store.getters['notifications'])

            const go = (route: any) => router.push(route)

            const readNotification = (id: number) => {
                store.dispatch('readNotification', id)
            }

            return {
                items,
                notifications: notifications.value,
                go,
                readNotification,
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
