
<div class="page-customized">
    <v-progress-linear
        v-if="overlay"
        absolute
        indeterminate
    ></v-progress-linear>

    <app-bar
        :title="badge.exists() ? t('badge_title_update') : t('badge_title_create')"
        back="/badges/badges"
    />

    <v-layout class="mt-n8">
        <v-card
            width="100%"
            color="transparent"
            elevation="0"
            class="pb-8"
        >
            <v-card-text class="pb-0">
                <v-form class="mt-6">
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_label_name') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="badge.name"
                                type="text"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('name')"
                            ></v-text-field>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="badge.name"
                                :items="badge.translations['name']"
                                @touch="(v) => badge.translations['name'] = v"
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_label_section') }}</div>
                        <div class="horizontal-input">
                            <v-select
                                v-model="badge.badgeSectionId"
                                :items="sections"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                :error-messages="form.errors.get('badge_section_id')"
                            ></v-select>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_label_photo') }}</div>
                        <div class="horizontal-input">
                            <v-file-input
                                v-model="badge.photo"
                                required
                                outlined
                                prepend-icon=""
                                prepend-inner-icon="mdi-paperclip"
                                :append-outer-icon="badge.photoUrl ? 'mdi-eye-outline' : ''"
                                dense
                                :error-messages="photoForm.errors.get('photo')"
                                @click:append-outer="viewPhoto()"
                                @click:clear="badge.photo = undefined"
                            ></v-file-input>
                        </div>
                    </div>
                    <div class="form-group-horizontal-cols">
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('badge_label_trigger') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="badge.trigger"
                                    :items="badgeTriggers()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('trigger')"
                                ></v-select>
                            </div>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('badge_label_stat') }}</div>
                            <div class="horizontal-input">
                                <v-select
                                    v-model="badge.stat"
                                    :items="badgeStats()"
                                    required
                                    outlined
                                    append-icon="mdi-chevron-down"
                                    dense
                                    :error-messages="form.errors.get('stat')"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="badge.trigger === 'period_execution'"
                        class="form-group-horizontal-cols"
                    >
                        <div class="form-group-horizontal">
                            <v-toolbar
                                elevation="0"
                                color="transparent"
                                class="ml-n4"
                                style="margin-top: -7px"
                                height="56"
                            >
                                <span class="sub-label pr-14" style="color:#3D4A6B">{{ t('general_label_date_period') }}</span>

                                <date-picker-modal
                                    :value="badge.startAt"
                                    @updated="(v) => badge.startAt = v"
                                >
                                    <template #default="{ on, attrs }">
                                        <div
                                            v-on="on"
                                            v-bind="attrs"
                                        >
                                            <v-text-field
                                                v-model="badge.startAt"
                                                class="xs input-color-inherit"
                                                outlined
                                                dense
                                                hide-details
                                                readonly
                                                :label="t('general_label_from')"
                                            ></v-text-field>
                                        </div>
                                    </template>
                                </date-picker-modal>

                                <span class="sub-label px-2">-</span>

                                <date-picker-modal
                                    :value="badge.endAt"
                                    @updated="(v) => badge.endAt = v"
                                >
                                    <template #default="{ on, attrs }">
                                        <div
                                            v-on="on"
                                            v-bind="attrs"
                                        >
                                            <v-text-field
                                                v-model="badge.endAt"
                                                class="xs input-color-inherit"
                                                outlined
                                                dense
                                                hide-details
                                                readonly
                                                :label="t('general_label_to')"
                                                :error-messages="form.errors.get('end_at')"
                                            ></v-text-field>
                                        </div>
                                    </template>
                                </date-picker-modal>

                                <v-btn
                                    @click="badge.startAt = null; badge.endAt = null; badge.counter = 0"
                                    v-ripple="false"
                                    plain
                                    color="orange darken-4"
                                    class="ml-3"
                                    style="font-size: 12px"
                                >{{ t('general_action_clear_all') }}</v-btn>

                            </v-toolbar>
                        </div>
                        <div class="form-group-horizontal">
                            <div class="horizontal-label">{{ t('badge_label_counter') }}</div>
                            <div class="horizontal-input">
                                <v-text-field
                                    v-model="badge.counter"
                                    v-maska="'######'"
                                    type="number"
                                    step="1"
                                    min="0"
                                    required
                                    outlined
                                    dense
                                    :error-messages="form.errors.get('end_at')"
                                ></v-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_label_description') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="badge.description"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('description')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="badge.description"
                                :items="badge.translations['description']"
                                @touch="(v) => badge.translations['description'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_label_motto') }}</div>
                        <div class="horizontal-input">
                            <v-textarea
                                v-model="badge.motto"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('motto')"
                            ></v-textarea>
                        </div>
                        <div class="horizontal-actions">
                            <edit-translations-modal
                                v-model="badge.motto"
                                :items="badge.translations['motto']"
                                @touch="(v) => badge.translations['motto'] = v"
                                text
                            ></edit-translations-modal>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('badge_label_sort_order') }}</div>
                        <div class="horizontal-input">
                            <v-text-field
                                v-model="badge.sortOrder"
                                type="number"
                                step="1"
                                required
                                outlined
                                dense
                                :error-messages="form.errors.get('sort_order')"
                            ></v-text-field>
                        </div>
                    </div>
                    <div class="form-group-horizontal">
                        <div class="horizontal-label">{{ t('general_action_published') }}</div>
                        <div class="horizontal-input">
                            <v-switch
                                class="ma-0"
                                v-model="badge.published"
                            ></v-switch>
                        </div>
                    </div>
                </v-form>
            </v-card-text>

            <v-card-actions class="px-4">
                <v-btn
                    :to="{ name: 'badges' }"
                    class="mr-4"
                    color="primary"
                >{{ t('general_action_cancel') }}</v-btn>
                <v-btn
                    :loading="form.busy"
                    :disabled="form.busy"
                    color="orange"
                    @click.prevent="submit"
                >{{ badge.exists() ? t('general_action_save') : t('badge_title_create') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-layout>
</div>
