import { useHttp } from '../http'

export const useDynamicLinkHttp = () => {

    const create = <T>(payload: any): Promise<T> => {
        return useHttp().post(`dynamic-links`, payload)
    }

    return {
        create
    }
}
