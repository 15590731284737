<template>
    <div>
        <app-bar
            :title="user.exists() ? user.fullName + (user.suspended ? ` <span class='orange--text'>(` + userStatusTitle(user.status).toLowerCase() + ')</span>' : '') : ''"
        >
            <template #actions>
                <div
                    v-if="user.exists() && user.isNotDeleted()"
                    class="px-10"
                >
                    <confirm-modal
                        v-if="! user.deleted"
                        :title="t('general_title_confirm_action')"
                        @agree="suspend(user)"
                    >
                        <template #default="{ on, attrs }">
                            <v-btn
                                class="sm sm-h mr-5"
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                            >{{ user.suspended ? t('general_action_unsuspend') : t('general_action_suspend') }}</v-btn>
                        </template>
                    </confirm-modal>
                    <confirm-modal
                        v-if="! user.deleted"
                        :title="t('general_title_confirm_action')"
                        @agree="del(user)"
                    >
                        <template #default="{ on, attrs }">
                            <v-btn
                                class="sm sm-h"
                                color="orange"
                                v-on="on"
                                v-bind="attrs"
                            >{{ t('general_action_delete') }}</v-btn>
                        </template>
                    </confirm-modal>
                </div>
            </template>
        </app-bar>

        <v-layout>
            <v-progress-linear
                v-if="overlay"
                absolute
                indeterminate
            ></v-progress-linear>

            <v-tabs
                v-model="currentTab"
                class="px-4 my-7"
                height="52"
            >
                <div class="tabs-line"></div>
                <v-tabs-slider></v-tabs-slider>
                <v-tab class="pl-0">{{ t('user_tab_trainee_summary') }}</v-tab>
                <v-tab>{{ t('user_tab_personal_information') }}</v-tab>
                <v-tab>{{ t('user_tab_programs') }}</v-tab>
                <v-tab>{{ t('user_tab_workouts') }}</v-tab>
                <v-tab>{{ t('user_tab_races') }}</v-tab>
                <v-tab>{{ t('user_tab_groups') }}</v-tab>
                <v-tab>{{ t('user_tab_events') }}</v-tab>

                <v-tab-item>
                    <div class="float-left mr-10">
                        <card-unit :items="[
                                { text: t('user_label_id'), value: user.id },
                                { text: t('user_label_registration_date'), value: user.registeredAt.format('DD/MM/YYYY HH:mm') },
                                { text: t('user_label_status'), value: userStatusTitle(user.status) },
                                { text: t('user_label_email_address'), value: user.email },
                                { text: t('user_label_first_name'), value: user.firstName },
                                { text: t('user_label_last_name'), value: user.lastName },
                                { text: t('user_label_language'), value: localeTitle(user.preferredLocale) },
                                { text: t('user_label_push_notifications'), value: user.allowNotifications ? 'Yes' : 'No' },
                                { text: t('user_label_registered_with'), value: providerTitle(user.provider) },
                                { text: t('user_label_os_used'), value: osTitle(user.os) },
                                { text: t('user_label_agent'), value: user.userAgent || '-' },
                            ]">
                            <template #title>Info</template>
                        </card-unit>
                        <card-unit :items="[
                                { text: t('user_label_member_of'), value: stat.groupsMember + ' ' + t('general_suffix_groups') },
                                { text: t('user_label_events_attend'), value: stat.eventsInvolved + ' ' + t('general_suffix_attends') },
                            ]">
                            <template #title>{{ t('user_card_title_community') }}</template>
                        </card-unit>
                    </div>
                    <div class="float-left mr-10">
                        <card-unit :items="[
                                { text: t('user_label_level'), value: levelTitle(user.level) },
                                { text: t('user_label_fav_stroke'), value: user.favoriteStrokeTitle },
                                { text: t('user_label_locations'), value: locationsStrTitle(user.swimmingLocations) },
                                { text: t('user_label_goals'), value: user.goalsStrTitle },
                                { text: t('user_label_ffn'), value: user.ffn ? t('general_text_yes') : t('general_text_no') },
                            ]">
                            <template #title>{{ t('user_card_title_swimming_profile') }}</template>
                        </card-unit>
                        <card-unit :items="[
                                { text: t('user_label_active_training_program'), value: user.program ? user.program.name : '-' },
                                { text: t('user_label_completion'), value: ((user.program && user.program.currentUserExecution) ? user.program.currentUserExecution.progressPercentage : '0') + '%' },
                                { text: t('user_label_total_workouts_done'), value: user.workoutExecutionsCount },
                            ]">
                            <template #title>{{ t('user_card_title_activities') }}</template>
                        </card-unit>
                    </div>
                    <div class="float-left">
                        <div
                            style="font-size: 16px;
                                   line-height: 24px;
                                   font-weight: 600;
                                   padding: 30px 0 28px;
                                   color: #3D4A6B;"
                        >{{ t('user_label_comments') }}</div>
                        <template v-for="comment in comments.data">
                            <v-sheet
                                color="white"
                                elevation="0"
                                width="350"
                                rounded="lg"
                                class="mb-6 pa-4"
                                style="overflow: hidden"
                            >
                                <div class="float-left" style="width: 60px">
                                    <v-img
                                        :src="comment.user.photo"
                                        width="40"
                                        height="40"
                                        class="rounded-xl"
                                    ></v-img>
                                </div>
                                <div class="float-left" style="width: 258px">
                                    <div class="position-relative">
                                        <span
                                            class="customized-primary--text pr-2"
                                            style="font-weight: 500; font-size: 12px"
                                        >{{ comment.user.fullName }}</span>
                                        <span
                                            style="font-size: 12px; color: #B4C3E7"
                                        >{{ comment.createdAt.format('DD/MM/YYYY') }}</span>
                                        <v-menu
                                            v-if="comment.userId === auth.user().id"
                                            offset-y
                                            left
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    icon
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    absolute
                                                    right
                                                    class="mt-n2 mr-n6"
                                                    color="customized-primary"
                                                ><v-icon>mdi-dots-vertical</v-icon></v-btn>
                                            </template>
                                            <v-list dense>
                                                <user-comment-modal :value="comment" :id="user.id" @cancel="captureComments" @submit="captureComments">
                                                    <template #default="{ on, attrs }">
                                                        <v-list-item
                                                            v-on="on"
                                                            v-bind="attrs"
                                                        >
                                                            <v-list-item-title
                                                                class="customized-deep-primary--text"
                                                            >{{ t('general_action_edit') }}</v-list-item-title>
                                                        </v-list-item>
                                                    </template>
                                                </user-comment-modal>
                                                <v-list-item
                                                    @click.prevent="delComment(comment)"
                                                >
                                                    <v-list-item-title
                                                        class="customized-deep-primary--text"
                                                    >{{ t('general_action_delete') }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                    <div
                                        class="pt-2 customized-deep-primary--text"
                                        style="font-size: 12px; line-height: 18px; font-weight: 500"
                                    >{{ comment.comment }}</div>
                                </div>
                            </v-sheet>
                        </template>
                        <user-comment-modal :id="user.id" @submit="captureComments" v-if="user.id">
                            <template #default="{ on, attrs }">
                                <v-btn
                                    v-on="on"
                                    v-bind="attrs"
                                    color="orange"
                                    class="mt-7"
                                >{{ t('user_action_create_comment') }}</v-btn>
                            </template>
                        </user-comment-modal>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="float-left mr-10">
                        <card-unit :items="[
                                { text: t('user_label_id'), value: user.id },
                                { text: t('user_label_registration_date'), value: user.registeredAt.format('DD/MM/YYYY HH:mm') },
                                { text: t('user_label_registered_with'), value: providerTitle(user.provider) },
                                { text: t('user_label_os_used'), value: osTitle(user.os) },
                                { text: t('user_label_first_name'), value: user.firstName },
                                { text: t('user_label_last_name'), value: user.lastName },
                                { text: t('user_label_gender'), value: genderTitle(user.gender) },
                                { text: t('user_label_birthday'), value: user.birthDate ? moment(user.birthDate).format('DD.MM.YYYY') : '' },
                                { text: t('user_label_weight'), value: user.weight },
                                { text: t('user_label_height'), value: user.height },
                                { text: t('user_label_city'), value: user.city },
                                { text: t('user_label_country'), value: user.countryCodeIso },
                                { text: t('general_label_coordinates'), value: user.coordinatesTitle },
                            ]">
                            <template #title>
                                {{ t('user_card_title_personal_data') }}
                                <v-spacer></v-spacer>
                                <user-personal-modal :value="user" @cancel="capture" @submit="capture">
                                    <template #default="{ on, attrs }">
                                        <a
                                            href="javascript:"
                                            v-on="on"
                                            v-bind="attrs"
                                        ><v-icon left>mdi-pencil</v-icon> {{ t('general_action_edit') }}</a>
                                    </template>
                                </user-personal-modal>
                            </template>
                        </card-unit>
                        <card-unit :items="[
                                { text: t('user_label_watch'), value: activityProvidersStrTitle(user.watches) },
                                { text: t('user_label_application'), value: activityProvidersStrTitle(user.apps) },
                            ]">
                            <template #title>{{ t('user_card_title_connected_devices') }}</template>
                        </card-unit>
                    </div>
                    <div class="float-left">
                        <card-unit :items="[
                                { text: t('user_label_language'), value: localeTitle(user.preferredLocale) },
                                { text: t('user_label_email'), value: user.email },
                                { text: t('user_label_email_confirmed'), value: user.emailVerifiedAt ? 'Yes' : 'No' },
                                { text: t('user_label_phone'), value: user.phone },
                                { text: t('user_label_push_notifications'), value: user.allowNotifications ? 'Yes' : 'No' },
                            ]">
                            <template #unit_2 v-if="! user.emailVerifiedAt">
                                <v-btn
                                    @click="resendVerification(user)"
                                    class="exs ml-2"
                                    color="primary"
                                    outlined
                                    small
                                    :loading="overlay"
                                    :disabled="overlay"
                                >{{ t('user_action_resend_verification') }}</v-btn>
                            </template>
                            <template #title>
                                {{ t('user_card_title_crm') }}
                                <v-spacer></v-spacer>
                                <user-crm-modal :value="user" @cancel="capture">
                                    <template #default="{ on, attrs }">
                                        <a
                                            href="javascript:"
                                            v-on="on"
                                            v-bind="attrs"
                                        ><v-icon left>mdi-pencil</v-icon> {{ t('general_action_edit') }}</a>
                                    </template>
                                </user-crm-modal>
                            </template>
                        </card-unit>
                        <card-unit :items="[
                                { text: t('user_label_level'), value: levelTitle(user.level) },
                                { text: t('user_label_fav_stroke'), value: user.favoriteStrokeTitle },
                                { text: t('user_label_locations'), value: locationsStrTitle(user.swimmingLocations) },
                                { text: t('user_label_goals'), value: user.goalsStrTitle },
                                { text: t('user_label_distance_unit'), value: distanceUnitTitle(user.distanceUnit) },
                                { text: t('user_label_yearly_goal'), value: user.swimmingGoalMeters },
                                { text: t('user_label_weekly_sessions'), value: user.trainingCountWeek },
                            ]">
                            <template #title>
                                {{ t('user_card_title_profile') }}
                                <v-spacer></v-spacer>
                                <user-profile-modal :value="user" @cancel="capture" @submit="capture">
                                    <template #default="{ on, attrs }">
                                        <a
                                            href="javascript:"
                                            v-on="on"
                                            v-bind="attrs"
                                        ><v-icon left>mdi-pencil</v-icon> {{ t('general_action_edit') }}</a>
                                    </template>
                                </user-profile-modal>
                            </template>
                        </card-unit>
                        <card-unit
                            :items="friends.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })"
                            @routed="routed"
                        >
                            <template #title>
                                {{ t('user_card_title_friends') }}
                            </template>
                            <template #actions>
                                <v-card-actions
                                    class="px-0 mx-n3 mt-4"
                                    v-if="friendMeta && friendMeta.total > 0"
                                >
                                    <v-pagination
                                        v-model="friendQueries.page"
                                        :length="friendPageCount"
                                        total-visible="7"
                                    ></v-pagination>
                                    <span class="pagination-meta">{{ t('general_pagination_meta', { from: friendMeta.from, to: friendMeta.to, total: friendMeta.total }) }}</span>
                                </v-card-actions>
                            </template>
                        </card-unit>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <template v-if="touchedProgramExecution.exists()">
                        <v-app-bar
                            color="transparent"
                            height="84"
                            elevation="0"
                            class="px-0-nested mb-n6"
                        >
                            <v-toolbar-title>{{ touchedProgramExecution.program.name }}</v-toolbar-title>
                            <v-btn
                                @click="touchedProgramExecution.unset()"
                                plain
                                icon
                                absolute
                                right
                                class="mr-n8"
                            ><v-icon>mdi-close</v-icon></v-btn>
                        </v-app-bar>
                        <program-execution-view :execution="touchedProgramExecution"></program-execution-view>
                    </template>
                    <template v-else>
                        <div class="d-flex pt-8 pb-6">
                            <stat
                                class="mr-5"
                                :name="t('stat_label_programs_total').toString()"
                                :count="stat.programsTotal"
                            ></stat>
                            <stat
                                class="mr-5"
                                :name="t('stat_label_programs_quit_total').toString()"
                                :count="stat.programsQuitTotal"
                            ></stat>
                            <stat
                                :name="t('stat_label_programs_avg_completion_percentage').toString()"
                                :count="stat.programsAvgCompletionPercentage"
                            ></stat>
                        </div>
                        <program-execution-collection
                            v-if="user.exists()"
                            :user="user"
                            @touched="touchProgramExecution"
                        ></program-execution-collection>
                    </template>
                </v-tab-item>
                <v-tab-item>
                    <template v-if="touchedWorkoutExecution.exists()">
                        <v-app-bar
                            color="transparent"
                            height="84"
                            elevation="0"
                            class="px-0-nested mb-n6"
                        >
                            <v-toolbar-title>{{ touchedWorkoutExecution.workout.name }}</v-toolbar-title>
                            <v-btn
                                @click="touchedWorkoutExecution.unset()"
                                plain
                                icon
                                absolute
                                right
                                class="mr-n8"
                            ><v-icon>mdi-close</v-icon></v-btn>
                        </v-app-bar>
                        <workout-execution-view :execution="touchedWorkoutExecution" :user="user"></workout-execution-view>
                    </template>
                    <template v-else>
                        <div class="d-flex pt-8 pb-6">
                            <stat
                                class="mr-5"
                                :name="t('stat_label_workouts_total').toString()"
                                :count="stat.workoutsTotal"
                            ></stat>
                            <stat
                                class="mr-5"
                                :name="t('stat_label_workouts_openswim_total').toString()"
                                :count="stat.workoutsOpenswimTotal"
                            ></stat>
                            <stat
                                class="mr-5"
                                :name="t('stat_label_workouts_customized_total').toString()"
                                :count="stat.workoutsCustomizedTotal"
                            ></stat>
                            <stat
                                class="mr-5"
                                :name="t('stat_label_workouts_reported_total').toString()"
                                :count="stat.workoutsReportedTotal"
                            ></stat>
                            <stat
                                class="mr-5"
                                :name="t('stat_label_workouts_duration_total')"
                                :count="stat._workoutsDurationTotal()"
                                dense
                            ></stat>
                            <stat
                                :name="t('stat_label_workouts_distance_total')"
                                :count="stat._workoutsDistanceTotal()"
                                dense
                            ></stat>
                        </div>
                        <workout-execution-collection
                            v-if="user.exists()"
                            :user="user"
                            @touched="touchWorkoutExecution"
                        ></workout-execution-collection>
                    </template>
                </v-tab-item>
                <v-tab-item>
                    <race-execution-collection v-if="user.exists()" :user="user"></race-execution-collection>
                </v-tab-item>
                <v-tab-item>
                    <div class="d-flex pt-8 pb-6">
                        <stat
                            class="mr-5"
                            :name="t('stat_label_groups_member').toString()"
                            :count="stat.groupsMember"
                        ></stat>
                        <stat
                            :name="t('stat_label_groups_created').toString()"
                            :count="stat.groupsCreated"
                        ></stat>
                    </div>
                    <group-collection
                        v-if="user.exists()"
                        :user="user"
                    ></group-collection>
                </v-tab-item>
                <v-tab-item>
                    <div class="d-flex pt-8 pb-6">
                        <stat
                            class="mr-5"
                            :name="t('stat_label_events_involved').toString()"
                            :count="stat.eventsInvolved"
                        ></stat>
                        <stat
                            class="mr-5"
                            :name="t('stat_label_events_future').toString()"
                            :count="stat.eventsFuture"
                        ></stat>
                        <stat
                            class="mr-5"
                            :name="t('stat_label_events_past').toString()"
                            :count="stat.eventsPast"
                        ></stat>
                        <stat
                            :name="t('stat_label_events_created').toString()"
                            :count="stat.eventsCreated"
                        ></stat>
                    </div>
                    <event-collection
                        v-if="user.exists()"
                        :user="user"
                    ></event-collection>
                </v-tab-item>
            </v-tabs>
        </v-layout>
    </div>
</template>

<script lang="ts">
    import moment from 'moment/moment'
    import { computed, defineComponent, onMounted, reactive, ref, toRefs } from '@vue/composition-api'
    import { useI18n } from 'vue-i18n-composable'
    import { useStore } from '@/store'
    import { useRouter } from '@/composition-api'
    import { useForm } from '@/core/form'
    import { useEnums, useLocales } from '@/core/defaults'
    import { useAuth } from '@/plugins/auth'
    import { User } from '@/core/models/user'
    import { UserStat } from '@/core/models/user-stat'
    import { UserComment } from '@/core/models/user-comment'
    import { TrainingProgramExecution } from '@/core/models/training-program-execution'
    import { WorkoutExecution } from '@/core/models/workout-execution'
    import CardUnit from '@/components/CardUnit.vue'
    import UserPersonalModal from '@/components/users/UserPersonalModal.vue'
    import UserCommentModal from '@/components/users/UserCommentModal.vue'
    import UserCrmModal from '@/components/users/UserCrmModal.vue'
    import UserProfileModal from '@/components/users/UserProfileModal.vue'
    import ProgramExecutionCollection from '@/components/program-executions/ProgramExecutionCollection.vue'
    import ProgramExecutionView from '@/components/program-executions/ProgramExecutionView.vue'
    import WorkoutExecutionCollection from '@/components/workout-executions/WorkoutExecutionCollection.vue'
    import WorkoutExecutionView from '@/components/workout-executions/WorkoutExecutionView.vue'
    import RaceExecutionCollection from '@/components/race-executions/RaceExecutionCollection.vue'
    import GroupCollection from '@/components/groups/GroupCollection.vue'
    import EventCollection from '@/components/events/EventCollection.vue'
    import Stat from '@/components/Stat.vue'
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import { UserCollection } from '@/store/modules/user-collection'
    import { UserCommentCollection } from '@/store/modules/user-comment-collection'
    import { pageCount } from '@/core/utils/pagination'

    export default defineComponent({
        components: {
            CardUnit,
            UserPersonalModal,
            UserCommentModal,
            UserCrmModal,
            UserProfileModal,
            ProgramExecutionCollection,
            ProgramExecutionView,
            WorkoutExecutionCollection,
            WorkoutExecutionView,
            RaceExecutionCollection,
            GroupCollection,
            EventCollection,
            Stat,
            ConfirmModal,
        },

        /**
         * Component constructor.
         *
         * @param props
         */
        setup(props) {
            const store = useStore()
            const router = useRouter()
            const id = router.currentRoute.params['id']

            const i18n = useI18n()
            const auth = useAuth()

            const friendQueries = reactive({
                follower_id: id,
                page: 1,
                per_page: 20,
                sort: '-id',
                only_active: 1,
            })

            const state = reactive({
                overlay: true,
                currentTab: parseInt(<any>router.currentRoute.query.tab),
                friendMeta: undefined,
                friendPageCount: 1,
            })

            const friends = computed(() => <UserCollection>store.getters['userCollection/get'])
            const comments = computed(() => <UserCommentCollection>store.getters['userCommentCollection/get'])

            const capture = () => {
                return store.dispatch('user/get', {
                    id: id,
                    payload: { append: 'user_goals,user_strokes,user_training_program,workout_executions_count' }
                }).then(() => {
                    store.dispatch('userCollection/get', friendQueries).then((data) => {
                        state.friendMeta = data.meta
                        state.friendPageCount = pageCount(data.meta)
                        state.overlay = false
                    })
                    captureComments()
                })
            }

            const captureComments = () => {
                store.dispatch('userCommentCollection/get', { recipient_id: id, relations: 'user' })
            }

            const userModel = computed(() => <User>store.getters['user/get'])
            const stat = computed(() => <UserStat>store.getters['userStat/get'])
            const touchedProgramExecution = ref(new TrainingProgramExecution(<any>{}))
            const touchedWorkoutExecution = ref(new WorkoutExecution(<any>{}))

            const form = reactive(useForm())

            const submit = () => {
                form.usingSubmit(store.dispatch('user/update', { id: id, payload: userModel.value.payload() }))
                    .then(() => {
                        store.dispatch('notify', { color: 'success', message: i18n.t('general_notice_data_updated') })
                    })
            }

            const touchProgramExecution = (execution: TrainingProgramExecution) => {
                Object.assign(touchedProgramExecution.value, execution)
            }

            const touchWorkoutExecution = (execution: WorkoutExecution) => {
                Object.assign(touchedWorkoutExecution.value, execution)
            }

            const back = () => {
                router.push({ name: 'trainees', params: <any>{ id: id } })
            }

            const suspend = (user: User) => {
                state.overlay = true
                store.dispatch('user/update', {
                    id: user.id,
                    payload: { status: user.status === 'active' ? 'suspended' : 'active' } }
                ).then(() => {
                    capture()
                })
            }

            const del = (user: User) => {
                state.overlay = true
                store.dispatch('user/delete', { id: user.id }).then(() => {
                    capture()
                })
            }

            const delComment = (comment: UserComment) => {
                state.overlay = true
                store.dispatch('userComment/delete', { id: comment.id }).then(() => {
                    captureComments()
                    state.overlay = false
                })
            }

            const resendVerification = (userModel: User) => {
                state.overlay = true
                store.dispatch('user/resendVerification', { id: userModel.id })
                    .then(() => {
                        store.dispatch('notify', { color: 'success', message: i18n.t('user_notice_confirmation_email_sent') })
                    }).catch(() => {
                        store.dispatch('notify', { color: 'error', message: i18n.t('user_notice_confirmation_email_sent_failure') })
                    }).finally(() => {
                        state.overlay = false
                    })
            }

            onMounted(() => {
                capture().then(() => store.dispatch('userStat/get', { id: userModel.value.id }))
            })

            return {
                auth,
                user: userModel.value,
                touchedProgramExecution: <TrainingProgramExecution>touchedProgramExecution.value,
                touchedWorkoutExecution: <WorkoutExecution>touchedWorkoutExecution.value,
                friends: friends.value,
                comments: comments.value,
                stat,
                form,
                capture,
                captureComments,
                routed: () => window.location.reload(),
                back,
                suspend,
                del,
                delComment,
                resendVerification,
                submit,
                touchProgramExecution,
                touchWorkoutExecution,
                moment,
                friendQueries,
                ...useEnums(),
                ...useLocales(),
                ...useI18n(),
                ...toRefs(state)
            }
        }
    })
</script>
