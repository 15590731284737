
<div>
    <app-bar
        :title="event.name"
    />

    <v-layout>
        <v-progress-linear
            v-if="overlay"
            absolute
            indeterminate
        ></v-progress-linear>

        <div class="pt-8 pb-12 px-8">
            <div v-if="permitted('admin_event_management')" class="pb-10">
                <v-btn
                    @click="edit"
                    class="sm mr-5"
                    color="primary"
                >{{ t('general_action_edit') }}</v-btn>
                <v-btn
                    class="sm mr-5"
                    color="primary"
                    outlined
                    @click.prevent="suspend(event)"
                >
                    {{ event.enabled ? t('general_action_suspend') : t('general_action_unsuspend') }}
                </v-btn>
                <v-btn
                    class="sm mr-5"
                    color="orange"
                    outlined
                    @click.prevent="del(event)"
                >{{ t('general_action_delete') }}</v-btn>
                <v-btn
                    class="sm"
                    color="purple"
                    outlined
                    @click.prevent="createDynamicLink(event)"
                >{{ t('general_action_create_dynamic_link') }}</v-btn>
            </div>
            <div class="float-left mr-10" style="max-width: 394px">
                <img
                    class=""
                    v-if="event.photoUrl"
                    :src="event.photoUrl"
                    width="394"
                />
                <div class="form-subtitle">{{ t('event_label_description') }}:</div>
                <div class="form-subtitle-description" v-html="event.description"></div>
                <card-unit :items="items()">
                    <template #title>{{ t('event_label_id') }}: {{ event.id }}</template>
                </card-unit>
                <template v-if="event.photos && event.photos.length">
                    <div class="form-subtitle mb-3">{{ t('event_label_photos') }}:</div>
                    <v-row>
                        <v-col
                            v-for="photo in event.photos"
                            :key="photo"
                            cols="6"
                        >
                            <v-sheet
                                color="white"
                                elevation="0"
                                height="185"
                                width="185"
                                rounded="lg"
                                class="pt-6"
                            >
                                <v-img
                                    :lazy-src="photo"
                                    :src="photo"
                                    max-height="135"
                                    max-width="135"
                                    class="mx-auto"
                                ></v-img>
                            </v-sheet>
                        </v-col>
                    </v-row>
                </template>
            </div>
            <div class="float-left mr-10">
                <v-tabs
                    v-model="currentTab"
                    height="52"
                >
                    <div class="tabs-line"></div>
                    <v-tabs-slider></v-tabs-slider>

                    <template>
                        <v-tab class="pl-0">{{ t('event_label_attendees') }}</v-tab>
                        <v-tab-item class="pt-7">
                            <card-unit
                                untitled
                                :items="participants.data.map(u => { return { text: u.fullName, route: { name: 'trainees.show', params: { id: u.id } } } })">
                                <template #actions>
                                    <v-card-actions
                                        class="px-0 mx-n3 mt-4"
                                        v-if="participantMeta && participantMeta.total > 0"
                                    >
                                        <v-pagination
                                            v-model="participantQueries.page"
                                            :length="participantPageCount"
                                            total-visible="7"
                                        ></v-pagination>
                                        <span class="pagination-meta">{{ t('general_pagination_meta', { from: participantMeta.from, to: participantMeta.to, total: participantMeta.total }) }}</span>
                                    </v-card-actions>
                                </template>
                            </card-unit>
                            <v-btn
                                v-if="participants.data.length"
                                @click="_exportUsers"
                                class="exs mt-2"
                                color="primary"
                                small
                            >{{ t('general_action_export') }}</v-btn>
                        </v-tab-item>

                        <v-tab>{{ t('event_label_ranking') }}</v-tab>
                        <v-tab-item class="pt-7">
                            <v-select
                                v-model="scheduleId"
                                :items="schedules"
                                required
                                outlined
                                append-icon="mdi-chevron-down"
                                dense
                                style="width: 394px"
                            ></v-select>
                            <card-unit
                                untitled
                                :items="ranks.data.map(r => { return { text: r.userFirstName + ' ' + r.userLastName + ' | ' + (event.targetType === 'distance' ? r.distance + ' m' : time.format(r.duration)) + ' | ' + r.progressPercentage + ' %', value: '#' + r.rank } })">
                                <template #actions>
                                    <v-card-actions
                                        class="px-0 mx-n3 mt-4"
                                        v-if="rankMeta && rankMeta.total > 0"
                                    >
                                        <v-pagination
                                            v-model="rankQueries.page"
                                            :length="rankPageCount"
                                            total-visible="7"
                                        ></v-pagination>
                                        <span class="pagination-meta">{{ t('general_pagination_meta', { from: rankMeta.from, to: rankMeta.to, total: rankMeta.total }) }}</span>
                                    </v-card-actions>
                                </template>
                            </card-unit>
                            <v-btn
                                v-if="ranks.data.length"
                                @click="_exportRanks"
                                class="exs mt-7"
                                color="primary"
                                small
                            >{{ t('general_action_export') }}</v-btn>
                        </v-tab-item>

                        <v-tab>{{ t('event_label_schedules') }}</v-tab>
                        <v-tab-item class="pt-7">
                            <card-unit
                                untitled
                                :items="event.schedules.map((s, i) => { return { text: '#' + (i + 1), value: s.startAt.format('DD/MM/YYYY HH:mm') + ' - ' + s.endAt.format('DD/MM/YYYY HH:mm') } })">
                            </card-unit>
                        </v-tab-item>
                    </template>
                </v-tabs>
            </div>
        </div>

    </v-layout>
</div>
